import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import Layout from "../../components/layout/Layout";
import VideoWrapper from "../../components/VideoWrapper";
import TeamPics from "../../components/TeamPics.js";
import Seo from "../../components/seo";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <Layout mdxType="Layout">
  <Seo title="About Us- pikespeakoralsurgery" description="Dr. Michael Kunkel and his surgical and office staff are a knowledgeable and compassionate group of individuals who strive to provide an outstanding experience" mdxType="Seo" />
  <section className="container" style={{
        margin: '128px auto'
      }}>
    <VideoWrapper video="HhcXkJ6Ltcc" centered mdxType="VideoWrapper" />
        <p>{`When choosing an oral and maxillofacial surgeon in the Colorado Springs area, we know that patients want to find an oral surgeon and surgical team that they feel comfortable with and trust to provide extraordinary care. Dr. Michael Kunkel and his experienced surgical and office staff are a knowledgeable and compassionate group of individuals who want to provide you with an outstanding experience in our office. Our entire team works together to ensure that every patient receives optimal results following surgery. They are ready to answer your questions regarding appointment preparation, treatment, office policies, and coordination of care with your regular restorative dentist.`}</p>
        <p>{`Our office utilizes a state-of-the-art surgical suite, digital imaging, and houses full instrument sterilization facilities to ensure our patient’s safety during every procedure. We use the latest surgical techniques and materials to provide our patients with the highest standard of care.`}</p>
        <h3 style={{
          marginTop: "64px"
        }}>
  Pikes Peak Oral Surgery & Dental Implant Center
        </h3>
        <p>{`3475 Briargate Boulevard, Suite 102
Colorado Springs, CO 80920
Phone: (719) 264-6070
Hours: Mon–Fri: 8:00 AM–5:00 PM`}</p>
        <p>{`As an oral and maxillofacial surgeon, Dr. Kunkel specializes in the treatment of injuries, infections, diseases, or defects of the oral and facial region. In addition to earning a bachelor’s degree and four years of dental school, oral and maxillofacial surgeons complete four additional years of hands-on, hospital-based surgical training. Oral and maxillofacial surgeons are trained to administer anesthesia and offer a variety of anesthetic and sedative options in their practice.`}</p>
        <p>{`Pikes Peak Oral Surgery & Dental Implant Center strives to build a foundation of trust by treating our patients with individualized care. We understand how uneasy some patients feel about seeing a dentist, and we want to make a difference in providing a calming and positive experience.`}</p>
        <p>{`Our team is dedicated to providing you with personalized care that exceeds your expectations. We thank you for allowing us to take care of your oral health needs and look forward to serving you.`}</p>
  <TeamPics mdxType="TeamPics" />
  </section>
    </Layout>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      